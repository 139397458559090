import React, { Fragment } from 'react';
import moment from 'moment';
import { List, Datagrid, TextField, FunctionField, } from 'react-admin';
import DeleteButtonWithConfirmation from '../layout/DeleteButtonWithConfirmation';

const DicountsActionButtons = props => (
  <Fragment>
    <DeleteButtonWithConfirmation {...props} />
  </Fragment>
);

const DicountsFilters = props => null;

const DicountsList = props => (
  <List {...props} filters={<DicountsFilters />} bulkActionButtons={<DicountsActionButtons />}>
    <Datagrid rowClick="show">
      <TextField source="code" sortable={false} />
      <TextField source="discount" label="Discount value" />
      <TextField source="discountUnit" />
      <FunctionField sortBy="createdAt" label="Created at" render={record => moment(record.createdAt).format('YYYY-MM-DD HH:mm')} />
      <FunctionField sortBy="expirationDate" label="Expires at" render={record => moment(record.expirationDate).format('YYYY-MM-DD HH:mm')} />
      <FunctionField label="Code consumed" render={record => (record.consumedAt ? 'Yes' : 'No')} />
    </Datagrid>
  </List>
);

export default DicountsList;
