import React from 'react';
import { getFormValues, change } from 'redux-form';
import {Edit, SimpleForm, DateInput, NumberInput, ReferenceInput, SelectInput, required, minValue } from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import { get, pick } from 'lodash';
import classnames from 'classnames';
import PaymentsSchedule from './PaymentsSchedule';
import Typography from '@material-ui/core/Typography';
const styles = {
  duration: {
    display: 'inline-block',
  },
  dateEnd: {
    display: 'inline-block',
    marginLeft: 32,
  },
  title: {
    marginTop: 20,
  }
}
const TZ = 'Europe/Lonodn';

const validate = (values, xxx) => {
  if (!get(values, 'contract')) {
    return {};
  }
  // console.log('VALS: ', values);
  // console.log('XXXX: ', xxx);
  const start = moment(values.dateStart).tz(TZ).startOf('day');
  const end = moment(values.dateEnd).tz(TZ).startOf('day');
  const contractStart = moment(values.contract.startDate).tz(TZ).startOf('day');
  const errors = {}
  // console.log('ST:', start);
  // console.log('CST: ', contractStart);
  // console.log('E: ', end);
  if (start.isBefore(contractStart)) {
    errors.dateStart = [`Discount start date can't be before contract start date ${contractStart.format('YYYY-MM-DD')}`]
  }
  if (end.isBefore(start)) {
    errors.dateEnd = [`The End date can't be before start date`]
  }
  return errors;
}
const ContractsDiscountEdit = ({formValues, change: formChange, classes, ...props}) => {
  React.useEffect(() => {
    if (formValues.duration === 0) {
      const contractEnd = moment(get(formValues, 'contract.endDate'));
      const discountEnd = moment(get(formValues, 'dateEnd'))
      if (discountEnd.isAfter(contractEnd)) {
        formChange('record-form', 'dateEnd', get(formValues, 'contract.endDate'));
      }
    }
  }, [formValues.duration]);
  // console.log('cLS: ', classes);
  // console.log('PPS: ', props);
  return (
    <>
    <Edit {...props} title="Edit Discount">
      <SimpleForm
        {...props}
        validate={validate}
        // redirect={redirect}
      >
        <ReferenceInput disabled label="Contract" source="contractId" reference="contracts" validate={[required()]}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <SelectInput source="discountUnit" validate={[required()]} choices={[{ id: '£', name: '£' }, { id: '%', name: '%' }]} />
        <NumberInput source="discount" label="Discount value" validate={[required(), minValue(1)]} inputProps={{ min: 1 }} />
        <DateInput source="dateStart" label="Start date" inputProps={{zmin: moment().format('YYYY-MM-DD'), max: '2999-12-31'}} />
        <SelectInput formClassName={classnames(classes.duration)} source="duration" validate={[required()]}
          choices={[
            { id: 1, name: '1 month' },
            { id: 2, name: '2 months' },
            { id: 3, name: '3 months' },
            { id: -1, name: 'Whole Contract' },
            { id: 0, name: 'Custom' },
            { id: 99, name: 'Forever', disabled: !get(formValues, 'contract.rolling') }
          ]}
        />
        {get(formValues, 'duration')  ===  0 && (
          <DateInput formClassName={classnames(classes.dateEnd)} source="dateEnd" label="End date" inputProps={{ min: get(formValues, 'dateStart'), max: '2999-12-31' }}/>
        )}
      </SimpleForm>
    </Edit>
    <Typography component="h2" variant="title" gutterBottom gutterTop className={classes.title}>
      Payments Schedule
    </Typography>
    <PaymentsSchedule id={get(formValues,'contract._id')} basePath='/contracts' record={{id: get(formValues,'contract._id')}} filter={{id: props.id, ...pick(formValues, 'discountUnit', 'discount', 'dateStart', 'duration', 'dateEnd'), type: 'discount'}}/>

    </>
  );
};

const mapStateToProps = state => ({
  formValues: getFormValues('record-form')(state) || {},
});

const enhance = connect(mapStateToProps, { change });

export default compose(withStyles(styles), enhance)(ContractsDiscountEdit);
// export default NotesCreate;
