import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { change } from 'redux-form';
import {TextInput} from 'react-admin';
import * as PropTypes from "prop-types";
import { connect } from 'react-redux';
import { withStyles, createStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';

const styles = theme => createStyles({
  autocompleteDropdownContainer: {
    fontFamily: theme.typography.fontFamily,
    position:'absolute',
    zIndex: 999,
    boxShadow: '0px 0px 5px #e0e0e0'
  },
  suggestionItemActive: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
    backgroundColor: '#fafafa',
    cursor: 'pointer'
  },
  suggestionItem: {
    alignItems: 'center',
    height: 30,
    backgroundColor: '#fefefe',
    cursor: 'pointer'
  },
  locationSearchInput: {}
});

const searchOptions = {
  componentRestrictions: {
    country: ['gb']
  },
  language: 'en'
};

class LocationInput extends React.Component {

  state = { address: '' };

  componentDidMount() {
    const { initAddress } = this.props;
    this.setState({address: initAddress})
  }

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        console.log('Success', latLng);
        this.props.onSelect(latLng)
        this.props.change('record-form', 'address', address);
      })
      .catch(error => console.error('Error', error));
  };

  render() {
    const { classes, validate} = this.props;
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <TextInput
              label="Address"
              source={'address'}
              {...getInputProps({
                validate,
                className: classes.locationSearchInput,
              })}
            />
            <div className={classes.autocompleteDropdownContainer}>
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? classes.suggestionItemActive
                  : classes.suggestionItem;
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

LocationInput.propTypes = {
  source: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  initAddress: PropTypes.string,
  validate: PropTypes.arrayOf(PropTypes.func)
};

const mapDispatchToProps = {
  change,
};

const enhance = compose(
  connect(null, mapDispatchToProps),
  withStyles(styles),
);

export default enhance(LocationInput)
