import React from 'react';
import { Show, SimpleShowLayout, FunctionField, TextField } from 'react-admin';
import isString from 'lodash/isString';

const MembersShow = props => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="email" />
        <TextField source="name" />
        <TextField source="space" />
        <FunctionField label="Published" render={record => (record.published ? 'Yes' : 'No')} />
        <TextField source="internalBalance" />
        <TextField source="job" />
        <TextField source="description" />
        <FunctionField label="Industry" render={record => ((isString(record.industry) ? [record.industry] : record.industry) || []).join(', ')} />
        <TextField source="billingDay" />
        <TextField source="joiningReason" />
        <TextField source="cancellationReason" />
      </SimpleShowLayout>
    </Show>
  );
};

export default MembersShow;
