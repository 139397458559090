import React from 'react';
import {Create, SimpleForm, FileField, FileInput, ReferenceInput, SelectInput, required } from 'react-admin';
import moment from 'moment';
import { parse } from 'query-string';

const DocumentsCreate = props => {
  const {contractId} = parse(props.location.search);
  const redirect = contractId ? `/contracts/${contractId}/show/documents` : `/contracts`;
  return (
    <Create {...props}>
      <SimpleForm
        defaultValue={{ created_at: moment.utc(), contractId }}
        redirect={redirect}
      >
        <ReferenceInput label="Contract" source="contractId" reference="contracts" validate={[required()]}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <FileInput source="pdf" label="Contract Document (PDF)" accept="application/pdf">
          <FileField source="src" title="title" />
        </FileInput>

      </SimpleForm>
    </Create>
  );
};

export default DocumentsCreate;