import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import API, { setGlobalParams, deleteGlobalParam, getDefaultSpace } from '../../_app/api';
import { API_ROUTES } from '../../_app/routes';
import { setSpace, spaceSet } from '../../_app/actions/setSpace';
const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 16,
  },
  formControl: {
    margin: theme.spacing.unit,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

class GlobalFilters extends React.Component {
  state = {
    filter: 'all',
    options: [],
  };

  async componentDidMount() {
    this.defaultSpace();
    this.fetchSpaces();
  }

  componentDidUpdate(prevProps) {
    if (this.props.totalSpaces > 0 && prevProps.totalSpaces !== this.props.totalSpaces) {
      this.fetchSpaces();
    }
  }

  defaultSpace = async () => {
    const spaceId = getDefaultSpace();
    const newState = {...this.state, filter: spaceId && spaceId !== 'null' ? spaceId : 'all'}
    this.setState(newState);
    this.props.setSpace(spaceId);
    this.props.spaceSet(true);
    if (spaceId) {
      setGlobalParams({spaceId})
    } else {
      deleteGlobalParam('spaceId');
    }
  }

  fetchSpaces = async () => {
    const {
      data: { data: options },
    } = await API(`${API_ROUTES.SPACES}?limit=50&order=ASC&sort=name`);
    this.setState({ options });
  };

  handleChange = event => {
    const {
      location: { pathname },
      push,
    } = this.props.history;
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.value === 'all') {
      this.props.setSpace(null);
      deleteGlobalParam('spaceId');
    } else {
      this.props.setSpace(event.target.value);
      setGlobalParams({ spaceId: event.target.value });
    }
    this.props.refreshView();
    if (pathname.includes('/spaces')) {
      if (event.target.value === 'all') {
        push('/spaces');
      } else {
        push(`/spaces/${event.target.value}`);
      }
    }
  };

  render() {
    const { classes } = this.props;
    const { options } = this.state;
    // console.log('FILTER: ', this.state);
    return (
      <div className={classes.root}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="filter">Filter</InputLabel>
          <Select
            value={this.state.filter}
            onChange={this.handleChange}
            inputProps={{
              name: 'filter',
              id: 'filter',
            }}
          >
            <MenuItem value="all">
              <em>Work.Life</em>
            </MenuItem>
            {options.map(({ _id, name }, idx) => (
              <MenuItem key={`global-filter-${idx}`} value={_id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }
}

GlobalFilters.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  totalSpaces: get(state.admin.resources, 'spaces.list.total', 0),
});

export default withStyles(styles)(connect(mapStateToProps, {setSpace, spaceSet})(GlobalFilters));
