import omit from 'lodash/omit'
import numeral from 'numeral';
import React, { Fragment } from 'react';
import {
  Datagrid,
  Filter,
  List,
  NumberField,
  ReferenceField,
  ArrayField,
  SingleFieldList,
  BooleanField,
  FunctionField,
  TextField,
  TextInput,
  downloadCSV,
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import Chip from '@material-ui/core/Chip'
import DeleteButtonWithConfirmation from '../layout/DeleteButtonWithConfirmation';

const PrivateRoomsActionButtons = props => (
  <Fragment>
    <DeleteButtonWithConfirmation {...props} />
  </Fragment>
);

const PrivateRoomsFilters = props => (
  <Filter {...props}>
    <TextInput source="name" />
  </Filter>
);

const exporter = async (records, fetchRelatedRecords) => {
  const spaces = await fetchRelatedRecords(records, 'spaceId', 'spaces');
  const data = records.map(record => ({
    spaceName: spaces[record.spaceId].name,
    ...omit(record, ['_id', 'spaceId']),
    occupiers: (record.occupiers || []).join(', '),
    deposits: (record.deposits || []).filter(({ name })=> name).map(({ name }) => name).join(', '),
  }));
  const csv = convertToCSV({
      data,
  });
  downloadCSV(csv, 'privateOffices');
};

const PrivateOfficesList = ({permissions, ...props}) => (
  <List
    {...props}
    exporter={exporter}
    perPage={25}
    filters={<PrivateRoomsFilters />}
    sort={{field: 'number', order: 'ASC'}}
    bulkActionButtons={permissions === 'ADMIN' ? <PrivateRoomsActionButtons /> : null}
  >
    <Datagrid rowClick="show">
      <ReferenceField allowEmpty label="Space" source="spaceId" reference="spaces" linkType={false}>
        <TextField source="name" />
      </ReferenceField>
      <TextField source="number" label="Name" />
      <NumberField source="numberOfDesks" label="# of desks" />
      <NumberField source="price" elstyle={{ fontWeight: 'bold' }} label="Desk price" options={{ style: 'currency', currency: 'GBP' }} />
      <BooleanField source="internal" />
      <BooleanField source="occupied" label="Occupied?" />
      <ArrayField label="Occupiers" source="occupiers">
        <SingleFieldList linkType={false}>
          <FunctionField render={record => (<Chip style={{margin:4}} label={record} clickable={false}/>)} />
        </SingleFieldList>
      </ArrayField>
      <FunctionField label="actual monthly income" render={record => `£${numeral(record.actualIncome).format('0,0.00')}`}/>
      <FunctionField label="List price" render={record => `£${numeral(record.price * record.numberOfDesks).format('0,0.00')}`} />
      <FunctionField label="Difference" render={record => <div style={{color: record.diff >= 0 ? 'green' : 'red'}}>£{numeral(record.diff).format('0,0.00')}</div>}/>
      <ArrayField label="Deposit Paid" source="deposits">
        <Datagrid>
          <TextField label="Occupier" source="name" />
          <BooleanField label="Paid?" source="depositPaid" />
        </Datagrid>
      </ArrayField>
    </Datagrid>
  </List>
);
export default PrivateOfficesList;
