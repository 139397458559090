import React from 'react';
import { BooleanInput } from 'react-admin';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { isUndefined } from 'lodash'
import { withStyles, createStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import classnames from 'classnames';

const styles = theme =>
  createStyles({
    label: {
      color: 'rgba(0, 0, 0, 0.541176)',
      fontFamily: theme.typography.fontFamily,
      fontSize: 13,
      lineHeight: '1em',
      height: '1em',
      marginRight: 10,
      width: 22,
    },
    container: {
      display: 'flex',
      alignItems: 'center',
    }
  });


const TheBooleanInput = ({formValues, ...props}) => {

  const {source, defaultValue, record, classes = {} } = props;
  return (<div className={classnames(classes.container)}>
    <span className={classnames(classes.label)}>{findValue(source, formValues, record, defaultValue) ? 'Yes' : 'No'}</span>
    <BooleanInput {...props} />
  </div>);
};

const findValue = (name, form, rec, def) => !isUndefined(form[name]) ? form[name] : (!isUndefined(rec[name]) ? rec[name] : (!isUndefined(def) ? def : false));



const mapStateToProps = state => ({
  formValues: getFormValues('record-form')(state) || {},
});

const enhance = connect(mapStateToProps);

export default compose(withStyles(styles), enhance)(TheBooleanInput);
