import React from 'react';
import {
  NumberInput,
  required,
  minValue,
  SimpleForm,
  SaveButton,
  TextInput,
  Toolbar,
  // ReferenceInput,
  // SelectInput,
} from 'react-admin';
import { integer } from '../_app/validate.js';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import LocationInput from '../_common/components/LocationInput';
import { change } from 'redux-form';

const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const SpaceEditToolbar = withStyles(styles)(({ classes, ...props }) => (
  <Toolbar className={classes.toolbar} {...props}>
    <SaveButton {...props} />
  </Toolbar>
));

const SpacesForm = ({ formValues, change: formChange, ...props }) => (
  <SimpleForm
    {...props}
    redirect={basePath => basePath}
    toolbar={<SpaceEditToolbar />}
  >
    <TextInput source="name" validate={[required()]} />
    <TextInput source="subdomain" validate={[required()]} />
    <LocationInput
      initAddress={props.record.address}
      source="location"
      validate={[required()]}
      onSelect={latlng => {
        formChange('record-form', 'location', {
          latitude: latlng.lat.toString(),
          longitude: latlng.lng.toString(),
        })
      }}
    />
    <NumberInput
      source="numberOfHotDesks"
      validate={[required(), minValue(0), integer]}
      inputProps={{ min: 0}}
      step={1}
      defaultValue={0}
    />
    <NumberInput
      source="multiplier"
      validate={[required(), minValue(1)]}
      inputProps={{ min: 1.0, step: 0.1 }}
    />
    <NumberInput
      source="localPrice"
      label="Local - price per desk (monthly)"
      validate={[required(), minValue(1)]}
      inputProps={{ min: 1.0 }}
    />
    <NumberInput
      source="numberOfDedicatedDesks"
      validate={[required(), minValue(0), integer]}
      inputProps={{ min: 0 }}
      defaultValue={0}
    />
    <NumberInput
      source="numberOfResidentDesks"
      validate={[required(), minValue(0), integer]}
      inputProps={{ min: 0 }}
      defaultValue={0}
    />
    <NumberInput
      source="numberOfLockers"
      validate={[required(), minValue(0), integer]}
      inputProps={{ min: 0 }}
      defaultValue={0}
    />
    <NumberInput
      source="residentPrice"
      validate={[required(), minValue(0), integer]}
      inputProps={{ min: 0 }}
      defaultValue={0}
    />
    <NumberInput
      source="extraDeskPrice"
      validate={[required(), minValue(0), integer]}
      inputProps={{ min: 0 }}
      defaultValue={0}
    />
    {/* <ReferenceInput
      label="Smartsheet"
      source="smartsheetId"
      reference="smartsheet"
      validate={[required()]}
    >
      <SelectInput optionText="name" />
    </ReferenceInput> */}
  </SimpleForm>
);

const mapStateToProps = state => ({
  formValues: getFormValues('record-form')(state) || {},
});

const enhance = connect(mapStateToProps, { change });

export default enhance(SpacesForm);
