import React, {createElement} from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import capitalize from 'lodash/capitalize';
import filter from 'lodash/filter';
import { MenuItemLink, DashboardMenuItem, getResources, refreshView as refreshViewAction } from 'react-admin';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import GlobalFilters from '../_common/components/GlobalFilters';

const styles = {
  sidebar: {
    width: 240,
    height: 'auto',
    position: 'relative',
    overflowX: 'hidden',
    transition: 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  hiddenSidebar: {
    width: 60,
  },
  dividerWrapper: {
    margin: '16px 0px',
  },
  sectionTitle: {
    fontSize: 20,
    padding: '12px 16px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    transition: 'font-size 195ms',
  },
  sectionTitleClosed: {
    fontSize: 10,
    padding: '12px 5px',
  },
};

const MySidebar = ({ resources, sidebarOpen, refreshView, classes, onMenuClick, logout, ...props }) => {
  const theResources = filter(resources, resource => !!resource.icon)
  return (
    <div className={classnames(classes.sidebar, sidebarOpen && classes.hiddenSidebar)}>
      <GlobalFilters refreshView={refreshView} history={props.history} />
      <DashboardMenuItem onClick={onMenuClick} />
      {theResources.map(resource => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={
            (resource.options && resource.options.label) ||
            resource.name
              .split('_')
              .map(capitalize)
              .join(' ')
          }
          leftIcon={createElement(resource.icon)}
          onClick={onMenuClick}
        />
      ))}
    </div>
  );
};

const mapStateToProps = state => ({
  resources: getResources(state),
  sidebarOpen: state.admin.ui.sidebarOpen,
});

const enhance = compose(withStyles(styles), withRouter, connect(mapStateToProps, { refreshView: refreshViewAction }));

export default enhance(MySidebar);
