import get from 'lodash/get';
import toLower from 'lodash/toLower';
import omit from 'lodash/omit';
import moment from 'moment';
import numeral from 'numeral';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import React, { Fragment } from 'react';
import {
  AutocompleteArrayInput,
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  downloadCSV,
  Filter,
  FunctionField,
  List,
  ReferenceField,
  SearchInput,
  TextField,
  TextInput,
} from 'react-admin';

import DeleteButtonWithConfirmation from '../layout/DeleteButtonWithConfirmation';

const ContractsActionButtons = (props) => (
  <Fragment>
    <DeleteButtonWithConfirmation {...props} />
  </Fragment>
);

const plans = [
  { id: 'Local', name: 'Local' },
  { id: 'Resident', name: 'Resident' },
  { id: 'Dedicated Desk', name: 'Dedicated Desk' },
];

const ContractsFilters = (props) => (
  <Filter {...props}>
    <TextInput source="name" />
    <AutocompleteArrayInput
      source="plan"
      choices={plans}
      translateChoice={false}
    />
    <BooleanInput source="expired" />
    <SearchInput source="q" alwaysOn />
  </Filter>
);

const rowStyle = (record, index) => ({
  backgroundColor: `${
    !record.rolling
      ? moment().isAfter(moment(record.leavingDate || record.endDate))
        ? 'rgba(255, 0, 0, 0.2)'
        : 'rgba(255, 255, 0, 0.2)'
      : 'transparent'
    }`,
});

const exporter = async (records, fetchRelatedRecords) => {
  const spaces = await fetchRelatedRecords(records, 'spaceId', 'spaces');
  const data = records.map((record) => {
    const plan = toLower(get(record, 'plan', ''));
    const spaceName = get(spaces, `${[record.spaceId]}.name`);
    return {
      ...omit(record, ['_id', 'id', 'cancellationContractEndDate', 'expired', 'desksNumber', 'monthlyFee', 'officeNumber', 'spaceId', 'xxx', 'rates', 'totalMembers']),
      Space: spaceName,
    };
  });
  const csv = convertToCSV({ data });
  downloadCSV(csv, `contracts_${moment().toISOString()}`);
};

const ContractsList = (props) => (
  <List
    {...props}
    perPage={25}
    filters={<ContractsFilters />}
    bulkActionButtons={<ContractsActionButtons />}
    exporter={exporter}
  >
    <Datagrid rowClick="show" rowStyle={rowStyle}>
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="company" />
      <ReferenceField
        allowEmpty
        label="Space"
        source="spaceId"
        reference="spaces"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="plan" />
      <FunctionField
        label="Monthly fee"
        render={(record) => `£${numeral(record.income).format('0,0.00')}`}
      />
      <TextField source="source" />
      <DateField source="startDate" />
      <DateField source="endDate" />
      <BooleanField source="depositPaid" />
      <TextField source="noticePeriod" />
      <BooleanField source="rolling" />
      <DateField source="leavingDate" />
    </Datagrid>
  </List>
);
export default ContractsList;
