import axios from 'axios';
import qs from 'query-string';
import {LOCAL_STORAGE_KEYS} from "./constants";

const { REACT_APP_RAICHU_URL: API_URL } = process.env;

const API = axios.create({
  baseURL: API_URL,
  paramsSerializer: params => {
    const { spaceId, filters, ...restParams } = params;
    return qs.stringify({ ...restParams, filters: JSON.stringify({ spaceId, ...filters }) });
  },
});

export const setGlobalParams = params => {
  API.defaults.params = {
    ...API.defaults.params,
    ...params,
  };
};

export const deleteGlobalParam = param => {
  if (API.defaults.params) {
    delete API.defaults.params[param];
  }
};

export const getRole = () => localStorage.getItem(LOCAL_STORAGE_KEYS.role);
export const getToken = () => localStorage.getItem(LOCAL_STORAGE_KEYS.token);
export const getDefaultSpace = () => localStorage.getItem(LOCAL_STORAGE_KEYS.space);

export const setToken = ({ token, role, spaceId }) => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.token, token);
  localStorage.setItem(LOCAL_STORAGE_KEYS.role, role);
  if (spaceId) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.space, spaceId);
  } else {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.space);
  }
  API.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const clearToken = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.token);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.role);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.space);
  delete API.defaults.headers.common.Authorization;
};

const AUTH_TOKEN = getToken();
if (AUTH_TOKEN) {
  API.defaults.headers.common.Authorization = `Bearer ${AUTH_TOKEN}`;
}

export default API;
