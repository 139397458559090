import React, { useEffect } from 'react';
import { Edit, email, ReferenceInput, regex, required, SelectInput, AutocompleteInput, SimpleForm, TextInput } from 'react-admin';
import { ROLES_ADMINISTRATIVE } from '../_app/constants';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';


const onKeyDown = e => {
  if (e.keyIdentifier === 'U+000A' || e.keyIdentifier === 'Enter' || e.keyCode === 13) {
    if (e.target.nodeName === 'INPUT' && e.target.type === 'text') {
      e.preventDefault();
      return false;
    }
  }
};


const AdminsEdit = ({ formValues, ...props }) => {
  useEffect(() => {
    window.addEventListener('keydown', onKeyDown, true);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    }
  });
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm>
        <TextInput source="email" validate={[required(), email()]} />
        <TextInput source="firstName" validate={[required(), regex(/^[a-zA-Z\s]+$/, 'Only A-Z a-z and whitespace allowed')]}/>
        <TextInput source="lastName" validate={[required(), regex(/^[a-zA-Z\s]+$/, 'Only A-Z a-z and whitespace allowed')]}/>
        <SelectInput
          source="role"
          validate={[required()]}
          choices={ROLES_ADMINISTRATIVE}
        />
        <ReferenceInput label="Space" source="spaceId" reference="spaces" allowEmpty sort={{ field: 'name', order: 'ASC' }}>
          <AutocompleteInput optionText={choice => choice.name}/>
        </ReferenceInput>
        <TextInput source="jobTitle" validate={[regex(/^[a-zA-Z\s]+$/, 'Only A-Z a-z and whitespace allowed')]}/>
      </SimpleForm>
    </Edit>
  );
};

const mapStateToProps = state => ({
  formValues: getFormValues('record-form')(state) || {},
});

const enhance = connect(mapStateToProps);

export default enhance(AdminsEdit);
