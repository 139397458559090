import React from 'react';
import { Create } from 'react-admin';
import PrivateRoomsForm from './PrivateOfficesForm';

const PrivateOfficesCreate = props => (
  <Create {...props}>
    <PrivateRoomsForm />
  </Create>
);

export default PrivateOfficesCreate;
