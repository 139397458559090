import React from 'react';
import {get, find} from 'lodash'
import moment from 'moment';
import numeral from 'numeral'

import {
  Show,
  TabbedShowLayout,
  Tab,
  ReferenceField,
  TextField,
  NumberField,
  BooleanField,
  FunctionField,
  DateField,
  ReferenceArrayField,
  ChipField,
  SingleFieldList,
  ReferenceManyField,
  Datagrid,
  EditButton,
  DeleteButton,
} from 'react-admin';

import {
  PdfIcon,
  ContractSummary,
  ContractDocs,
  ContractNotes,
  ContractDiscount,
  ContractSchedule,
} from '../_app/icons';

import { PLANS, BUSINESS_TYPES, SOURCES, NOTICE_PERIODS, INDUSTRIES } from "../_app/constants";
import PreviewNoteButton from './PreviewNoteButton';
import AddDocumentButton from './AddDocumentButton';
import AddNoteButton from './AddNoteButton';
import AddContractDiscountButton from './AddContractDiscountButton';
import AddContractChargeButton from './AddContractChargeButton';
import PaymentsSchedule from './PaymentsSchedule';

const STATUSES = {
  'active': 'green',
  'pending': 'orange',
  'expired': 'red',
};

const NotePreview = ({record}) => (<PreviewNoteButton id={record.id}/>);
// const
const ContractTitle = ({record}) => `Contract ${record.company || record.name}`;

const ContractsShow = props => (
  <Show {...props} title={<ContractTitle/>}>
    <TabbedShowLayout>
      <Tab label="Summary" icon={<ContractSummary/>}>
        <ReferenceField
          allowEmpty
          label="Space"
          source="spaceId"
          reference="spaces"
        >
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          label="Plan"
          render={record => get(find(PLANS, {id: record.plan}), 'name')}
        />
        <TextField source="firstName" label="First Name" />,
        <TextField source="lastName" label="Last Name" />,
        <TextField source="email" />,
        <TextField source="phone" label="Mobile Number" />,
        <TextField source="company" label="Company Name" />,
        <TextField source="agreementNumber" />
        <DateField source="startDate" label="Start Date" />,
        <DateField source="endDate" label="Earliest End Date"  />
        <DateField source="leavingDate" label="Leaving Date"  />,
        <ReferenceArrayField label="Offices" source="officeIds" reference="private_offices">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <FunctionField
          label="Business Type"
          render={record => get(find(BUSINESS_TYPES, {id: record.businessType}), 'name')}
        />
        <FunctionField
          label="Industry"
          render={record => get(find(INDUSTRIES, {id: record.industry}), 'name')}
        />



      <NumberField
      source="desksNumber"
      label={`No. of seats`}
    />
      <NumberField
        source="deposit"
      />
      <BooleanField label="Deposit Paid" source="depositPaid" />,
      {/* <NumberField
        source="monthlyFee"
      /> */}

      <FunctionField
        label="Source"
        render={record => get(find(SOURCES, {id: record.source}), 'name')}
      />
      <BooleanField label="Expired" source="expired" defaultValue={false} />,
      <BooleanField label="Rolling" source="rolling" defaultValue={true} />,
      <FunctionField
        label="Notice Period"
        source="noticePeriod"
        render={record => get(find(NOTICE_PERIODS, {id: record.noticePeriod}), 'name')}
        defaultValue={2}
      />
 */}

      </Tab>
      <Tab label="Documents" icon={<ContractDocs/>} path="documents">
        <ReferenceManyField
            addLabel={false}
            sort={{ field: 'date', order: 'DESC' }}
            reference="documents"
            target="contractId"
        >
          <Datagrid>
            <FunctionField label="Download" render={record => record.pdf ? (<a href={record.pdf.src} target='blank'><PdfIcon/></a>) : (<b>N/A</b>)} />
            <FunctionField label="Name" render={record => get(record, 'pdf.title', '')} />
            <DateField source="createdAt" showTime/>
            <ReferenceField allowEmpty source="creatorId" reference="admins" linkType={false}>
              <FunctionField render={user => `${user.firstName} ${user.lastName}`} />
            </ReferenceField>
            {/* <TextField source="reference" />
              <NbItemsField />
              <NumberField
                  source="total"
                  options={{ style: 'currency', currency: 'USD' }}
              />
              <TextField source="status" /> */}
              <FunctionField render={record=> (<DeleteButton resource="documents" basePath="/documents" record={record} redirect={`/contracts/${record.contractId}/show/documents`} />)}/>
          </Datagrid>
        </ReferenceManyField>
        <AddDocumentButton style={{marginTop: 10}}  />
      </Tab>
      <Tab label="Notes" icon={<ContractNotes/>} path="notes">
        <ReferenceManyField
            addLabel={false}
            sort={{ field: 'createdAt', order: 'DESC' }}
            reference="notes"
            target="contractId"
        >
          <Datagrid>
            <DateField source="createdAt" showTime />
            <ReferenceField allowEmpty source="creatorId" reference="admins" linkType={false}>
              <FunctionField render={user => `${user.firstName} ${user.lastName}`} />
            </ReferenceField>
            <NotePreview/>
          </Datagrid>
        </ReferenceManyField>
        <AddNoteButton />
      </Tab>
      <Tab label="Discounts" icon={<ContractDiscount/>} path="discounts">
        <ReferenceManyField
              addLabel={false}
              sort={{ field: 'createdAt', order: 'DESC' }}
              reference="contract_discounts"
              target="contractId"
        >
          <Datagrid>
            <DateField source="createdAt" showTime />
            <ReferenceField  allowEmpty source="creatorId" reference="admins" linkType={false}>
              <FunctionField render={user => `${user.firstName} ${user.lastName}`} />
            </ReferenceField>
            <DateField source="dateStart" label="Start" />
            <FunctionField label="Date end" render={({ duration, dateEnd }) => duration === 99 ? 'Forever' : moment(dateEnd).format('DD.MM.YYYY')}/>
            <FunctionField label="Value" render={discount => discount.discountUnit === '£' ? `£${numeral(discount.discount).format('0,00.00')}`: `${numeral(discount.discount).format('0.00')}%`}/>
            <FunctionField label="Status" render={ discount => (<ChipField source='status' record={discount} style={{color: '#fff', backgroundColor: STATUSES[discount.status]}}/>)}/>
            <FunctionField render={record=> (<DeleteButton resource="contract_discounts" basePath="/contract_discounts" record={record} redirect={`/contracts/${record.contractId}/show/discounts`} />)}/>
            <EditButton/>
          </Datagrid>
        </ReferenceManyField>
        <AddContractDiscountButton />
      </Tab>
      <Tab label="Charges" icon={<ContractDiscount/>} path="charges">
        <ReferenceManyField
              addLabel={false}
              sort={{ field: 'createdAt', order: 'DESC' }}
              reference="contract_charges"
              target="contractId"
        >
          <Datagrid>
            <DateField source="createdAt" showTime />
            <ReferenceField allowEmpty source="creatorId" reference="admins" linkType={false}>
              <FunctionField render={user => `${user.firstName} ${user.lastName}`} />
            </ReferenceField>
            <DateField source="dateStart" label="Start" />
            <DateField source="dateEnd" label="End"/>
            <FunctionField label="Value" render={discount => discount.discountUnit === '£' ? `£${numeral(discount.discount).format('0,00.00')}`: `${numeral(discount.discount).format('0.00')}%`}/>
            <FunctionField label="Status" render={ discount => (<ChipField source='status' record={discount} style={{color: '#fff', backgroundColor: STATUSES[discount.status]}}/>)}/>
            <FunctionField render={record=> (<DeleteButton resource="contract_charges" basePath="/contract_charges" record={record} redirect={`/contracts/${record.contractId}/show/charges`} />)}/>
            <EditButton/>
          </Datagrid>
        </ReferenceManyField>
        <AddContractChargeButton />
      </Tab>
      <Tab label="Payment Schedule" icon={<ContractSchedule/>} path="schedule">
        <PaymentsSchedule {...props}/>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default ContractsShow;
