import React from 'react';
import { Edit } from 'react-admin';
import SpacesForm from './SpacesForm';

const Title = ({record}) => record.name;
const SpacesEdit = props => (
  <Edit title={<Title/>} {...props} undoable={false}>
    <SpacesForm />
  </Edit>
);

export default SpacesEdit;
