import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {

    primary: {
      main: 'rgb(33,40,65)',
      light: 'rgb(33,40,65)',
      dark: 'rgb(23,23,62)',
      contrastText: '#fff',
    },
    secondary: {
      main: 'rgb(33,40,65)',
      light: 'rgb(33,40,65)',
      dark: '#000000',
      contrastText: '#fff',
    }
  },
  typography: {
    fontFamily: [
      'HalisR-Medium',
      'Work Sans',
      '-apple-system',
      'Helvetica Neue',
      'sans-serif'
    ].join(','),
  },
});
