import React from 'react';
import { Edit } from 'react-admin';
import PrivateRoomsForm from "./PrivateOfficesForm";

const Title = ({record}) => `Private Office ${record.number}`;
const PrivateOfficesEdit = props => (
  <Edit {...props} undoable={false} title={<Title/>}>
    <PrivateRoomsForm />
  </Edit>
);

export default PrivateOfficesEdit;
