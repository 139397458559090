import { CRUD_CREATE_FAILURE, CRUD_UPDATE_FAILURE } from 'react-admin';
import { stopSubmit } from 'redux-form';
import { put, takeEvery } from 'redux-saga/effects';


export function* crudCreateOrUpdateFailure({ payload }) {
  yield put(stopSubmit('record-form', payload));
}

export default function* errorsSage() {
  yield takeEvery(CRUD_CREATE_FAILURE, crudCreateOrUpdateFailure);
  yield takeEvery(CRUD_UPDATE_FAILURE, crudCreateOrUpdateFailure);
}
