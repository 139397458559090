import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import { get } from 'lodash';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import {
  ReferenceInput,
  ReferenceArrayInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  TabbedForm,
  FormTab,
  RadioButtonGroupInput,
  TextInput,
  // DateInput,
  NumberInput,
  required,
  minValue,
  Toolbar,
  SaveButton,
  SelectInput,
  email,
  number,
  ReferenceManyField,
  Datagrid,
  FunctionField,
  DateField,
  ReferenceField,
  DeleteButton,
  EditButton,
  ChipField,
  FileInput,
  FileField,
} from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';

import { withStyles } from '@material-ui/core/styles';

import PreviewNoteButton from './PreviewNoteButton';
import AddDocumentButton from './AddDocumentButton';
import AddNoteButton from './AddNoteButton';
import AddContractDiscountButton from './AddContractDiscountButton';
import AddContractChargeButton from './AddContractChargeButton';

import {
  ContractSummary,
  ContractNotes,
  ContractDocs,
  ContractDiscount,
  ContractSchedule,
  PdfIcon,
} from '../_app/icons';
import PaymentsSchedule from './PaymentsSchedule';
import BooleanInput from '../_common/components/BooleanInput';
import DeleteButtonWithConfirmation from '../layout/DeleteButtonWithConfirmation';
import { PLANS, SOURCES, NOTICE_PERIODS, INDUSTRIES } from '../_app/constants';

const validate = ({ from, to }) => {
  const errors = {};
  if (moment(from).isAfter(moment(to))) {
    errors.from = ['Start time must be before end time'];
  }
  return errors;
};
const STATUSES = {
  'active': 'green',
  'pending': 'orange',
  'expired': 'red',
};
const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
};


const ContractsEditToolbar = withStyles(styles)(({ classes, ...props }) => (
  <Toolbar className={classes.toolbar} {...props}>
    <SaveButton {...props} />
    {/* <AddDocumentButton {...props} />
    <AddNoteButton {...props} />
    <AddContractDiscountButton {...props} />
    <AddContractChargeButton {...props} />
    <DeleteButtonWithConfirmation {...props} /> */}
  </Toolbar>
));
const ContractsForm = ({ formValues, create, ...props }) => (
  <TabbedForm {...props} validate={validate} toolbar={<ContractsEditToolbar />} defaultValue={{ shouldCreateMembership: true }}>
    <FormTab label="Summary" icon={<ContractSummary />}>
      <TextInput
        label="Cobot Member URL"
        source="membershipUrl"
        reference="spaces"
        sort={{ field: 'name', order: 'ASC' }}
        validate={[required()]}
      />
      <FileInput source="pdf" label="Contract Document (PDF)" accept="application/pdf" validate={[required()]}>
        <FileField source="src" title="title" />
      </FileInput>
    </FormTab>
  </TabbedForm>
);

const mapStateToProps = state => ({
  formValues: getFormValues('record-form')(state) || {},
});

const enhance = connect(mapStateToProps);

export default enhance(ContractsForm);
