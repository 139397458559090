import {SET_SPACE, SPACE_SET} from '../actions/setSpace';


export const setSpace = (prev = '',{type, payload}) => {
  if (type === SET_SPACE) {
    return payload.space;
  }
  return prev;
};
export const spaceSet = (prev = false,{type, payload}) => {
  if (type === SPACE_SET) {
    return payload;
  }
  return prev;
};