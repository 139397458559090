
import { ReferenceField, Datagrid, TextField, FunctionField, NumberField, BooleanField } from 'react-admin';
import React from 'react';
import compose from 'recompose/compose';
import numeral from 'numeral';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { pick } from 'lodash';
import DashList from './DashList';
const styles = {
  title: {
    marginTop: 35,
    marginBottom: 15,
  }
}
const VacantOffices = ({ classes, ...props }) => {

      return (
        <>
          <Typography component="h2" variant="title" gutterBottom className={classes.title}>
            Current Vacant Offices
          </Typography>
          <DashList
            {...pick(props, ['location'])}
            title=" "
            resource='reports/free-offices'
            basePath='/reports/free-offices'
            filters={null}
            actions={null}
            bulkActionButtons={false}
            sort={{field: 'number', order: 'ASC'}}
          >
            <Datagrid rowClick={row => `/private_offices/${row}/show`}>
              <ReferenceField  reference="spaces" source="spaceId" allowEmpty linkType={false}>
                <TextField source="name"/>
              </ReferenceField>
              <TextField source="number" label="Name" />
              <NumberField label="Number of Desks" source="numberOfDesks"/>
              <FunctionField label="Price per desk" render={record => `£${numeral(record.price).format('0,0')}`} />
              <BooleanField label="Internal" source="internal"/>

            </Datagrid>
          </DashList>
        </>
      );
};

const enhance = compose(
  withStyles(styles)
);

export default enhance(VacantOffices);
