import { withStyles } from '@material-ui/core/styles';

import moment from 'moment';
import React from 'react';
import BooleanInput from '../_common/components/BooleanInput';
import { integer } from '../_app/validate';
import { AutocompleteInput, minValue, NumberInput, ReferenceInput, required, SaveButton, SimpleForm, Toolbar } from 'react-admin';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';

import DeleteButtonWithConfirmation from '../layout/DeleteButtonWithConfirmation';

const validate = ({ from, to }) => {
  const errors = {};
  if (moment(from).isAfter(moment(to))) {
    errors.from = ['Start time must be before end time'];
  }
  return errors;
};

const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const PrivateRoomsEditToolbar = withStyles(styles)(({ classes, ...props }) => (
  <Toolbar className={classes.toolbar} {...props}>
    <SaveButton {...props} />
    <DeleteButtonWithConfirmation {...props} />
  </Toolbar>
));

const PrivateOfficesForm = ({ formValues, ...props }) => (
  <SimpleForm {...props} validate={validate} redirect={basePath => basePath} toolbar={<PrivateRoomsEditToolbar />}>
    <ReferenceInput label="Space" source="spaceId" reference="spaces" validate={[required()]} sort={{ field: 'name', order: 'ASC' }}>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <NumberInput
      inputProps={{
        min: 0,
      }}
      source="number"
      label="Name"
      validate={[required(), minValue(0)]}
    />
    <NumberInput
      source="numberOfDesks"
      validate={[required(), minValue(1), integer]}
      inputProps={{
        min: 1,
      }}
    />
    <NumberInput
      source="price"
      label="Monthly price per desk"
      validate={[required(), minValue(0)]}
      inputProps={{
        min: 0,
      }}
    />
    <BooleanInput label="Internal" source="internal" />
  </SimpleForm>
);

const mapStateToProps = state => ({
  formValues: getFormValues('record-form')(state) || {},
});

const enhance = connect(mapStateToProps);

export default enhance(PrivateOfficesForm);
