import React, { Fragment} from 'react';
import { CardActions, CreateButton, Datagrid, ExportButton, List, TextField, Filter, TextInput, ReferenceField, } from 'react-admin';
import DeleteButtonWithConfirmation from "../layout/DeleteButtonWithConfirmation";
import { connect } from "react-redux";
import { push } from 'react-router-redux';

const AdminsFilters = props => (
  <Filter {...props}>
    <TextInput source="email" alwaysOn/>
    <TextInput source="firstName" />
    <TextInput source="lastName" />
  </Filter>
);

const AdminsActionButtons = props => (
  <Fragment>
    <DeleteButtonWithConfirmation {...props} />
  </Fragment>
);

const AdminsListActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  push
}) => (
  <CardActions>
    {bulkActions && React.cloneElement(bulkActions, {
      basePath,
      filterValues,
      resource,
      selectedIds,
      onUnselectItems,
    })}
    {filters && React.cloneElement(filters, {
      resource,
      showFilter,
      displayedFilters,
      filterValues,
      context: 'button',
    }) }
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
    {/* <Button size="small" color="primary" onClick={() => push('/membersImports/create')}>
      <Attachment style={{ width: 20, height: 20, marginRight: 6.5 }} />
      Import
    </Button> */}
  </CardActions>
);

const AdminsList = props => (
  <List {...props} filters={<AdminsFilters />} bulkActionButtons={<AdminsActionButtons />} actions={<AdminsListActions push={props.push}/>}>
    <Datagrid rowClick="edit">
      <TextField source="email" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="role" />
      <ReferenceField allowEmpty label="Space" source="spaceId" reference="spaces">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export default connect(null, { push })(AdminsList);
