import { Query, Loading, Error } from 'react-admin';
import { connect } from 'react-redux';
import numeral from 'numeral'
import React from 'react';
import Chart from '../_common/components/Chart';

class IncomeDiscountChart extends React.Component {

  state = {
    reloading: false,
  };

  componentDidMount() {
    this.setState({reloading: false});
  }

  shouldComponentUpdate(nextProps) {
    const {spaceId: currentSpace, ready: currentReady} = this.props;
    const {spaceId: nextSpace, ready: nextReady} = nextProps;
    return (nextSpace !== currentSpace) || (currentReady !== nextReady);
  }

  render () {
    // console.log('S: ', this.state);
    const { spaceId, type, ready } = this.props;
    const { reloading } = this.state;
    // console.log(`READY: ${ready}, RELOAD: ${reloading}, SPACE: ${spaceId}`)
    if (reloading || !ready) {
      return <Loading loadingSecondary=""/>;
    }
    return(
      <Query
        type="GET_LIST"
        resource="reports/income_discount"
        payload={{
          filter: { spaceId },
          pagination: {},
          sort: {},
        }}
      >

        {({ data, loading, error }) => {
          // console.log(`RE-RENDER loading: ${loading}, ready: ${ready}, data:`, data);
          if (loading || !ready || !data || data[0].spaceId !== spaceId) {
            return <Loading loadingSecondary=""/>;
          }
          if (error) {
            return <Error/>;
          }
          return (
            <Chart
              config={{
                chart: {
                  type: 'column',
                  spacing: [20, 20, 20, 20],
                },
                plotOptions: {
                  column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: false
                    },
                  },
                },
                xAxis: {
                  type: 'category',
                },
                tooltip: {
                  valuePrefix: '£',
                  valueDecimals: 0,
                },
                yAxis: {
                  stackLabels: {
                    enabled: true,
                    formatter: function() {
                      return '£'+ numeral(this.total).format('0,000').replace(',',' ');
                    },
                  },
                  labels: {
                    format: '£{value}',
                  },
                },
                title: {
                  text: type === 'quarterly_income_discount' ? 'Quarterly Revenue Report' : 'Monthly Revenue Report',
                },
                series: data,
              }}
            />
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps)(IncomeDiscountChart);
