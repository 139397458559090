import React from 'react';
import { Show, SimpleShowLayout, FunctionField, TextField } from 'react-admin';
import moment from 'moment';

const DiscountsShow = props => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="code" />
        <TextField source="discount" label="Discount value" />
        <TextField source="discountUnit" />
        <FunctionField label="Created at" render={record => moment(record.createdAt).format('YYYY-MM-DD HH:mm')} />
        <FunctionField label="Expires at" render={record => moment(record.expirationDate).format('YYYY-MM-DD HH:mm')} />
        <FunctionField label="Code consumed" render={record => (record.consumedAt ? 'Yes' : 'No')} />
      </SimpleShowLayout>
    </Show>
  );
};

export default DiscountsShow;
