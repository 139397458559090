import React from 'react';
import {
  CardActions,
  Datagrid,
  ExportButton,
  List,
  TextField,
  ReferenceField,
  Filter,
  TextInput,
} from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

const MembersFilters = props => (
  <Filter {...props}>
    <TextInput source="email" alwaysOn />
    <TextInput source="name" />
  </Filter>
);

const MembersListActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  push,
}) => (
    <CardActions>
      {bulkActions &&
        React.cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems,
        })}
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton disabled={total === 0} resource={resource} sort={currentSort} filter={filterValues} exporter={exporter} />
    </CardActions>
  );

const MembersList = props => (
  <List {...props} filters={<MembersFilters />} bulkActionButtons={false} actions={<MembersListActions push={props.push} />}>
    <Datagrid rowClick="show">
      <TextField source="email" />
      <TextField source="name" />
      <ReferenceField label="Space" source="spaceId" reference="spaces" allowEmpty>
        <TextField source="name" />
      </ReferenceField>

    </Datagrid>
  </List>
);

export default connect(
  null,
  { push },
)(MembersList);
