
import Space from '@material-ui/icons/Landscape';
import Room from '@material-ui/icons/Room';
import PrivateOffice from '@material-ui/icons/Business';
import People from '@material-ui/icons/People';
import Account from '@material-ui/icons/AccountBalance';
import VerifiedUser from '@material-ui/icons/VerifiedUser'
import Receipt from '@material-ui/icons/Receipt';
import Discount from '@material-ui/icons/MonetizationOn';
import FileDownload from '@material-ui/icons/FileDownload';
import ChatBubble from '@material-ui/icons/ChatBubble';
import Payment from '@material-ui/icons/Payment';
import Folder from '@material-ui/icons/Folder';
import Schedule from '@material-ui/icons/DateRange';
import dollar from '@material-ui/icons/MonetizationOn';

export const spaceIcon = Space;
export const memberIcon = People;
export const resourceIcon = Room;
export const privateOfficeIcon = PrivateOffice;
export const companyIcon = Account;
export const adminIcon = VerifiedUser;
export const contractsIcon = Receipt;
export const documentsIcon = Receipt;
export const notesIcon = Receipt;
export const discountIcon = Discount;
export const PdfIcon = FileDownload;
export const dollarIcon = dollar;
export const ContractSummary = Receipt;
export const ContractDocs = Folder;
export const ContractNotes = ChatBubble;
export const ContractDiscount = Payment;
export const ContractSchedule = Schedule;