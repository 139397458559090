import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes, reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import compose from 'recompose/compose';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { theme } from '../_app/theme';
import { Notification, translate, showNotification } from 'react-admin';
import axios from 'axios';
import { API_ROUTES } from '../_app/routes';
const { REACT_APP_RAICHU_URL } = process.env;

const api = axios.create({
  baseURL: REACT_APP_RAICHU_URL,
});

const styles = theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    background: `linear-gradient(${theme.palette.primary.dark},${theme.palette.primary.main},${theme.palette.primary.dark})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  logoWrapper: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '40px 16px 0px 16px',
  },
  logoText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 30
  },
  separator: {
    marginTop: '15px',
    marginBottom: '5px'
  },
  card: {
    minWidth: 300,
    marginTop: '2em',
    fontFamily: theme.typography.fontFamily,
    textAlign: 'center'
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500],
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  actions: {
    padding: '0 1em 1em 1em',
  },
});

const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

class ResetPassword extends Component {
  resetPassword = auth => {
    const { showNotification } = this.props;
    api.post(API_ROUTES.PASSWORD_RESET, auth)
      .then(() => {
          showNotification('If we find your e-mail in our database, we\'ll send you instructions how to reset your password');
      })
      .catch((e) => {
          showNotification(`An error has occured: ${e.message}`, 'warning')
      });
  }

  render() {
    const { classes, handleSubmit, isLoading } = this.props;
    return (
      <div className={classes.main}>
        <Link to='/'><img src="/images/worklife-icon.png" width={200} alt="worklife logo"/></Link>
        <Card className={classes.card}>
          <div className={classes.logoWrapper}>
            <img src="/images/logo_dark.png" width={100} height={14} alt="office manager logo"/>
          </div>
          <div className={classes.separator}>Recover your password</div>
          <form onSubmit={handleSubmit(this.resetPassword)}>
            <div className={classes.form}>
              <div className={classes.input}>
                <Field
                  autoFocus
                  name="email"
                  component={renderInput}
                  label="Email"
                  disabled={isLoading}
                />
              </div>
            </div>
            <CardActions className={classes.actions}>
              <Button
                variant="raised"
                type="submit"
                color="primary"
                disabled={isLoading}
                className={classes.button}
                fullWidth
              >
                {isLoading && <CircularProgress size={25} thickness={2} />}
                Send me a reset link
              </Button>
            </CardActions>
          </form>
        </Card>
        <Notification />
      </div>
    );
  }
}

ResetPassword.propTypes = {
  ...propTypes,
  authProvider: PropTypes.func,
  classes: PropTypes.object,
  previousRoute: PropTypes.string,
  translate: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });
var emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const enhance = compose(
  translate,
  reduxForm({
    form: 'pwReset',
    validate: (values, props) => {
      const errors = {};
      const { translate } = props;
      if (!values.email) {
        errors.email = translate('ra.validation.required');
      } else {
        if (!emailRegEx.test(values.email)) {
          errors.email = 'not a valid e-mail address'
        }
      }
      return errors;
    },
  }),
  connect(
    mapStateToProps,
    { push, showNotification },
  ),
  withStyles(styles),
);

const EnhancedResetPassword = enhance(ResetPassword);

// We need to put the MuiThemeProvider decoration in another component
// Because otherwise the withStyles() HOC used in EnhancedLogin won't get
// the right theme
const ResetPasswordWithTheme = props => (
  <MuiThemeProvider theme={theme}>
    <EnhancedResetPassword {...props} />
  </MuiThemeProvider>
);

export default ResetPasswordWithTheme;
