import { ReferenceField, Datagrid, TextField, FunctionField, DateField } from 'react-admin';
import React from 'react';
import compose from 'recompose/compose';
import numeral from 'numeral';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import DashList from './DashList';
import { pick } from 'lodash';

const styles = {
  title: {
    marginTop: 35,
    marginBottom: 15,
  }
}
const Renewals = ({ classes, ...props }) => {
      return (
        <>
          <Typography component="h2" variant="title" gutterBottom className={classes.title}>
            Upcoming renewals
          </Typography>
          <DashList
            {...pick(props, ['location'])}
            resource='reports/renewals'
            basePath='/reports/renewals'
            filters={null}
            actions={null}
            bulkActionButtons={false}
            title="Dashboard"
            sort={{field: 'endDate', order: 'DESC'}}
          >
            <Datagrid rowClick={row => `/contracts/${row}/show`}>
              <ReferenceField  reference="spaces" source="spaceId" allowEmpty linkType={false}>
                <TextField source="name"/>
              </ReferenceField>
              <FunctionField label="Name" render={record => record.company || record.name}/>
              <FunctionField label="Income" render={record => `£${numeral(record.income).format('0,0')}`} />
              <DateField source="endDate" />
            </Datagrid>
          </DashList>
        </>
      );
};

const enhance = compose(
  withStyles(styles)
);

export default enhance(Renewals);
