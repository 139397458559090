import React from 'react';
import { Create } from 'react-admin';
import NewContractForm from './NewContractForm';

const ContractsCreate = props => (
  <Create {...props}>
    <NewContractForm create />
  </Create>
);

export default ContractsCreate;
