import { withDataProvider } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import IncomeDiscountChart from './IncomeDiscountChart';
import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import get from 'lodash/get';
// import { reduxForm } from 'redux-form';
import Renewals from './Renewals';
import Occupancy from './Occupancy'
import VacantOffices from './VacantOffices';
import EndingSoon from './EndingSoon';

class Dashboard extends React.Component {
  render() {
    return (
      <Grid container title="Dashboard">
        <Grid item xs={12}>
            <IncomeDiscountChart spaceId={this.props.spaceId} ready={this.props.ready} />
        </Grid>
        <Grid item xs={6}>
          <Occupancy spaceId={this.props.spaceId} ready={this.props.ready} report='occupancy' title='Occupancy (Local)' />
        </Grid>
        <Grid item xs={6}>
          <Occupancy spaceId={this.props.spaceId}  ready={this.props.ready} report='resident-occupancy' title='Occupancy (Resident)'/>
        </Grid>

        {/* <Grid item xs={6}>
            <IncomeDiscountChart spaceId={this.props.spaceId} type='quarterly_income_discount' />
        </Grid> */}
        <Grid item xs={12}>
          <Renewals {...this.props}  />
        </Grid>
        <Grid item xs={12}>
          <VacantOffices {...this.props}  />
        </Grid>
        <Grid item xs={12}>
          <EndingSoon {...this.props}  />
        </Grid>

      </Grid>
    );
  }
}

const mapStateToProps = state => {
  // console.log('ST: ', state);
  const spaceId = get(state, 'setSpace', '');
  const ready = get(state, 'spaceSet', false);
  return {
    spaceId,
    ready,
    location: get(state, 'router.location'),
    occupancy: get(
      state,
      `admin.resources.spaces.data.${spaceId}.occupancy`,
      {},
    ),
    income: get(state, `admin.resources.spaces.data.${spaceId}.income`, {}),
  };
};

const enhance = compose(
  withDataProvider,
  connect(mapStateToProps),
);

export default enhance(Dashboard);
