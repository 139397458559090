import React from 'react';
import {
  Show,
  SimpleShowLayout,
  FunctionField,
  NumberField,
  BooleanField,
  ReferenceField,
  TextField,
  ReferenceArrayField,
  SingleFieldList,
} from 'react-admin';
import Chip from '@material-ui/core/Chip';
import {values, reduce} from 'lodash';
import numeral from 'numeral';

const IncomeAggregate = ({data, ...props}) => {
  const contracts = values(data);
  const income = reduce(contracts, (sum, contract) => (sum + contract.actualIncome), 0);
  return (<FunctionField record={{income}} render={record => `£${numeral(record.income).format('0,0.00')}`}/>);
}

const IncomeDiffAggregate = ({record, label, data, ...props}) => {
  const contracts = values(data);
  const income = reduce(contracts, (sum, contract) => (sum + contract.monthlyFee), 0);
  const listPrice = record.price * record.numberOfDesks;
  const color = (listPrice < income ? 'green' : 'red')
  return (<FunctionField style={{color}} addLabel label={label} record={{income, listPrice}} render={record => `£${numeral(record.income - record.listPrice).format('0,0.00')}`}/>);
}

const ParentAwareReferenceArrayField =({record, label, source, reference, sortable, allowEmpty, ...props}) => {
  return (
    <ReferenceArrayField record={record} label={label} source={source} reference={reference} sortable={sortable} allowEmpty={allowEmpty} {...props}>
      <IncomeDiffAggregate record={record} label={label}/>
    </ReferenceArrayField>
  )
}
ParentAwareReferenceArrayField.defaultProps = {
  addLabel: true
};
const Title = ({record}) => `Private Office ${record.number}`;
const PrivateOfficesShow = props => {
  return (
    <Show {...props} title={<Title/>}>
      <SimpleShowLayout>
        <ReferenceField allowEmpty label="Space" source="spaceId" reference="spaces">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="number" label="Name" />
        <NumberField source="numberOfDesks" label="# of desks" />
        <NumberField source="price" elstyle={{ fontWeight: 'bold' }} label="Desk price" options={{ style: 'currency', currency: 'GBP' }} />
        <BooleanField source="internal" />
        <BooleanField label="Occupied?" source="occupied"/>
        <ReferenceArrayField label="Occupier" source="contractIds" reference="contracts" sortable={false} allowEmpty>
          <SingleFieldList>
            <FunctionField render={record => (<Chip style={{margin:4}} label={record.company || record.name}/>)} />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField label="actual monthly income" source="contractIds" reference="contracts" sortable={false} allowEmpty>
          <IncomeAggregate/>
        </ReferenceArrayField>
        <FunctionField label="actual monthly income" render={record => `£${numeral(record.actualIncome).format('0,0.00')}`} />
        <FunctionField label="List price" render={record => `£${numeral(record.price * record.numberOfDesks).format('0,0.00')}`} />
        <FunctionField label="Difference" render={record => {
          const listPrice = record.price * record.numberOfDesks;
          const income = record.actualIncome;
            const color = (listPrice < income ? 'green' : 'red')
          return (<FunctionField style={{color}} record={{diff:  income - listPrice}} render={r =>`£${numeral(r.diff).format('0,0.00')}`}/>)
        }}/>
      </SimpleShowLayout>
    </Show>
  );
}

export default PrivateOfficesShow;
