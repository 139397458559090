import React, { Fragment } from 'react';
import { List, Datagrid, TextField, Filter, TextInput } from 'react-admin';
import DeleteButtonWithConfirmation from "../layout/DeleteButtonWithConfirmation";

const SpacesActionButtons = props => (
  <Fragment>
    <DeleteButtonWithConfirmation {...props} />
  </Fragment>
);

const SpacesFilters = props => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
  </Filter>
);

const SpacesList = ({permissions, ...props}) => (
  <List {...props} filters={<SpacesFilters />} bulkActionButtons={permissions === 'ADMIN' ? <SpacesActionButtons /> : null}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="numberOfHotDesks" />
      <TextField source="numberOfResidentDesks" />
      <TextField source="numberOfDedicatedDesks" />
      <TextField source="numberOfLockers" />
    </Datagrid>
  </List>
);

export default SpacesList;
