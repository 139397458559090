import React from 'react';
import { Button, Link } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { ContractDocs } from '../_app/icons';

const styles = {
  button: {
    color: '#fff !important',
  },
};
const AddDocumentButton = withStyles(styles)(({classes, record}) => (
  <Button
    className={classes.button}
    style={{paddinTop: 8, paddingBottom: 8, marginLeft: 10}}
    variant="raised"
    component={Link}
    to={{
      pathname: "/documents/create",
      search: `?contractId=${record.id}`
    }}
    label="Add a document"
  >
    <ContractDocs/>
  </Button>
));

export default AddDocumentButton;