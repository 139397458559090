import React from 'react';
import { Query, Loading, Error } from 'react-admin';
import {map, toNumber} from 'lodash';
import Chart from '../_common/components/Chart';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  title: {
    marginTop: 35,
    marginBottom: 15,
  }
}

const prepareData = data => map(data, item => ({
  ...item,
  data: map(item.data, seria => ({...seria, y: toNumber(seria.y)})),
}))

const Occupancy = ({ classes, spaceId, report, title, ready }) => {
  if (!ready) {
    return <Loading loadingSecondary=""/>;
  }
  return <Query
    type="GET_LIST"
    resource={`reports/${report}`}
    payload={{
      filter: { spaceId },
      pagination: {},
      sort: {},
    }}
  >
    {({ data, loading, error }) => {
      if (loading || !ready || !data || data[0].spaceId !== spaceId) {
        return <Loading loadingSecondary=""/>;
      }
      if (error) {
        return <Error/>;
      }
      const mappedData = prepareData(data);
      return (
        <>
          <Typography component="h2" variant="title" gutterBottom className={classes.title}>
            {title}
          </Typography>
          <Chart
            config={{
              chart: {
                type: 'pie',
                spacing: [20, 20, 20, 20],
              },
              title: {
                text: 'Source',
              },
              tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.0f}% ({point.y:.0f})</b>',
              },
              plotOptions: {
                pie: {
                  allowPointSelect: true,
                  cursor: 'pointer',
                  dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.0f}% ({point.y:.0f})',
                  },
                },
              },
              series: mappedData,
            }}
          />
        </>
      );
    }}
  </Query>
};

export default withStyles(styles)(Occupancy);
