import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import API, { setToken, clearToken, getToken, getRole } from '../_app/api';
import { API_ROUTES } from '../_app/routes';

export default async (type, params) => {
  // console.log('AUTH: ', type);
  // console.log('PARAMS: ', params);
  if (type === AUTH_LOGIN) {
    const { username: email, password } = params;
    try {
      const {
        data: { token, role, spaceId },
      } = await API.post(API_ROUTES.SIGN_IN, { email, password });
      // console.log('ROLE: ', role);
      setToken({token, role, spaceId})
      return Promise.resolve();
    } catch (e) {
      return Promise.reject();
    }
  }

  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    clearToken();
    return Promise.resolve();
  }

  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      clearToken()
      return Promise.reject();
    }
    return Promise.resolve();
  }

  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    return getToken()
      ? Promise.resolve()
      : Promise.reject();
  }

  if (type === AUTH_GET_PERMISSIONS) {
    const role = getRole();
    return role ? Promise.resolve(role) : Promise.resolve(null);
}

  return Promise.reject('Unknown method');
};
