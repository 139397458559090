import numeral from 'numeral';
import React from 'react';
import IconImageEye from "@material-ui/icons/RemoveRedEye";
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  FunctionField,
  ChipField,
  SingleFieldList,
  ReferenceManyField,
  Button,
  Link,
} from 'react-admin';
import moment from 'moment';


const STATUSES = {
  'active': 'green',
  'pending': 'orange',
  'expired': 'red',
};



const PaymentSchedule = ({record}) => (
  <Button
    label="View Payment Schedule"
    component={Link}
    to={{
      pathname: `/contracts/${record.contractId}/show/schedule`,
    }}
  >
    <IconImageEye/>
  </Button>
);


const ContractDiscountsList = props => (
  <List
    {...props}
    actions={null}
    bulkActionButtons={false}
  >
    <Datagrid>
      <ReferenceField allowEmpty label="Space" source="contractId" reference="contracts" linkType={false}>
        <FunctionField render={record => (
          <ReferenceField record={record} basePath="contracts" reference="spaces" source="spaceId" allowEmpty linkType={false}>
            <TextField source="name"/>
          </ReferenceField>
        )}/>
      </ReferenceField>
      <ReferenceField allowEmpty label="Offices" source="contractId" reference="contracts" linkType={false}>
        <FunctionField render={record => (
          <ReferenceManyField record={record} basePath="contracts" reference="private_offices" target="contractIds" allowEmpty linkType={false}>
            <SingleFieldList linkType={false}>
              <ChipField source="name"/>
            </SingleFieldList>
          </ReferenceManyField>
        )}/>
      </ReferenceField>
      <ReferenceField allowEmpty label="Contract" source="contractId" reference="contracts" linkType={false}>
        <FunctionField render={record => record.company || record.name} />
      </ReferenceField>
      <FunctionField label="Discount" render={discount => discount.discountUnit === '£' ? `£${numeral(discount.discount).format('0,00.00')}`: `${numeral(discount.discount).format('0.00')}%`}/>
      <DateField source="dateStart" />
      <FunctionField label="Date end" render={({ duration, dateEnd }) => duration === 99 ? 'Forever' : moment(dateEnd).format('DD.MM.YYYY')}/>
      <FunctionField label="Status" sortBy="dateEnd" render={ discount => (<ChipField source='status' record={discount} style={{color: '#fff', backgroundColor: STATUSES[discount.status]}}/>)}/>
      <PaymentSchedule/>
    </Datagrid>
  </List>
);
export default ContractDiscountsList;
