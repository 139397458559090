import React from 'react';
import { Edit } from 'react-admin';
import ContractsForm from './ContractsForm';

const ContractTitle = ({ record }) => `Edit ${record.company || record.name}`;
const ContractsEdit = props => (
  <Edit {...props} undoable={false} title={<ContractTitle />}>
    <ContractsForm {...props} />
  </Edit>
);

export default ContractsEdit;
