import moment from 'moment-timezone';
import React from 'react';
import {
  ArrayField,
  ChipField,
  Datagrid,
  FunctionField,
  ReferenceManyField,
  SingleFieldList,
  TextField,
  ReferenceField,
  ReferenceArrayField,
} from 'react-admin';

import { PriceValueWithTooltip } from './PaymentsScheduleList';

const TZ = 'Europe/London';

const PaymentsSchedule = ({ ...props }) => {
  return (
    <ReferenceManyField
      {...props}
      addLabel={false}
      sort={{ field: 'date', order: 'ASC' }}
      reference="payment_schedules"
      target="contractId"
      filter={props.filter}
    >
      <Datagrid>
        <TextField source="name" sortBy="firstName" />
        <TextField source="company" />
        <ReferenceField
          allowEmpty
          label="Space"
          source="spaceId"
          reference="spaces"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceArrayField
          label="Offices"
          source="officeIds"
          reference="private_offices"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField source="plan" />
        <FunctionField
          label="Gross Income"
          render={({ grossIncome }) => (
            <PriceValueWithTooltip
              value={grossIncome}
              tooltipTitle="list price, pro rata if start/end mid month, + charges"
            />
          )}
        />
        <FunctionField
          label="Discount"
          render={({ discount }) => (
            <PriceValueWithTooltip
              value={discount}
              tooltipTitle="pro rata if start/end mid month, doesn't include charges"
            />
          )}
        />
        <ArrayField source="charges">
          <SingleFieldList>
            <FunctionField
              label="Charges"
              render={discount => (
                <ChipField source="discount" record={{ discount }} />
              )}
            />
          </SingleFieldList>
        </ArrayField>
        <FunctionField
          label="Net Price"
          render={({ netPrice }) => (
            <PriceValueWithTooltip
              value={netPrice}
              tooltipTitle="list price, pro rata if start/end mid month, + charges - discount"
            />
          )}
        />
        <FunctionField
          label="Payment Date"
          render={r =>
            moment.tz(r.paymentDate || r.leavingDate, TZ).format('MMM/YYYY')
          }
        />
      </Datagrid>
    </ReferenceManyField>
  );
};

export default PaymentsSchedule;
