import React from 'react';
import { Button, Link } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { ContractNotes } from '../_app/icons';

const styles = {
  button: {
    color: '#fff !important',
  },
};

const AddNoteButton = withStyles(styles)(({classes, record}) => (
  <Button
    className={classes.button}
    style={{paddinTop: 8, paddingBottom: 8, marginLeft: 10}}
    variant="raised"
    component={Link}
    to={{
      pathname: "/notes/create",
      search: `?contractId=${record.id}`
    }}
    label="Add a note"
  >
    <ContractNotes/>
  </Button>
));

export default AddNoteButton;