import React from 'react';
import { Route } from 'react-router-dom';
import { Admin, Resource } from 'react-admin';
import addUploadFeature from './providers/addUploadFeature';
import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';
import errorsSaga from './errorsSaga';
import './index.css';
import { theme } from './_app/theme';
import Layout from './layout/Layout';
import Login from './layout/Login';
import ResetPassword from './layout/ResetPassword';
import SetPassword from './layout/SetPassword';
import MembersList from './members/MembersList';
import MembersShow from './members/MembersShow';
import {
  adminIcon,
  memberIcon,
  privateOfficeIcon,
  spaceIcon,
  contractsIcon,
  discountIcon,
  ContractDiscount,
  dollarIcon,
} from './_app/icons';
import { setSpace, spaceSet } from './_app/reducers/setSpace'
import AdminsList from './admins/AdminsList';
import AdminsCreate from './admins/AdminsCreate';
import AdminsEdit from './admins/AdminsEdit';
import SpacesList from './spaces/SpacesList';
import SpacesEdit from './spaces/SpacesEdit';
import SpacesCreate from './spaces/SpacesCreate';
import DiscountsList from './discounts/DiscountsList';
import DiscountsCreate from './discounts/DiscountsCreate';
import DiscountsShow from './discounts/DiscountsShow';
import PrivateOfficesList from './privateOffices/PrivateOfficesList';
import PrivateOfficesEdit from './privateOffices/PrivateOfficesEdit';
import PrivateOfficesCreate from './privateOffices/PrivateOfficesCreate';
import Dashboard from './dashboard';
import ContractsArchiveList from './contracts/ContractsArchiveList';
import ContractsList from './contracts/ContractsList';
import ContractsEdit from './contracts/ContractsEdit';
import ContractsCreate from './contracts/ContractsCreate';
import ContractsShow from './contracts/ContractsShow';
import PrivateOfficesShow from './privateOffices/PrivateOfficesShow';
import DocumentsCreate from './contracts/DocumentsCreate';
import NotesCreate from './contracts/NotesCreate';
import ContractDiscountsCreate from './contracts/ContractDiscountsCreate';
import ContractDiscountsEdit from './contracts/ContractDiscountsEdit';
import ContractDiscountsList from './contracts/ContractDiscountsList';
import PaymentsScheduleList from './contracts/PaymentsScheduleList';
import ContractChargeCreate from './contracts/ContractChargeCreate';
import ContractChargeEdit from './contracts/ContractChargeEdit';

const dataProviderWithUploadFeature = addUploadFeature(dataProvider);

const App = () => {
  return (
    <Admin
      theme={theme}
      dashboard={Dashboard}
      dataProvider={dataProviderWithUploadFeature}
      customSagas={[errorsSaga]}
      customReducers={{ setSpace, spaceSet }}
      authProvider={authProvider}
      appLayout={Layout}
      loginPage={Login}
      customRoutes={[
        <Route exact path='/forgot-password' component={ResetPassword} noLayout />,
        <Route exact path='/reset-password/:token' component={SetPassword} noLayout />,
      ]}
    >
      {permissions => [
        permissions === 'ADMIN' ? <Resource
          name="portal_members"
          list={MembersList}
          show={MembersShow}
          icon={memberIcon}
          options={{ label: 'Members' }}
          context={{}}
        /> : null,
        permissions === 'ADMIN' ? <Resource
          name="admins"
          list={AdminsList}
          create={AdminsCreate}
          edit={AdminsEdit}
          icon={adminIcon}
          options={{ label: 'Office Managers' }}
          context={{}}
        /> : null,
        <Resource
          name="spaces"
          list={permissions === 'ADMIN' ? SpacesList : null}
          edit={permissions === 'ADMIN' ? SpacesEdit : null}
          create={permissions === 'ADMIN' ? SpacesCreate : null}
          icon={permissions === 'ADMIN' ? spaceIcon : null}
          options={{}}
          context={{}}
        />,
        permissions === 'ADMIN' ? <Resource
          name="discounts"
          list={DiscountsList}
          show={DiscountsShow}
          create={DiscountsCreate}
          icon={discountIcon}
          options={{
            label: 'Discount Codes',
          }}
          context={{}}
        /> : null,
        <Resource
          name="private_offices"
          list={PrivateOfficesList}
          edit={permissions === 'ADMIN' ? PrivateOfficesEdit : null}
          create={permissions === 'ADMIN' ? PrivateOfficesCreate : null}
          show={PrivateOfficesShow}
          icon={privateOfficeIcon}
          options={{
            label: 'Private Offices',
          }}
          context={{}}
        />,
        <Resource
          name="contracts"
          list={ContractsList}
          edit={ContractsEdit}
          create={ContractsCreate}
          show={ContractsShow}
          icon={contractsIcon}
          options={{}}
          context={{}}
        />,
        <Resource
          name="contracts_archive"
          list={ContractsArchiveList}
          icon={contractsIcon}
          options={{ label: "Archived Contracts" }}
          context={{}}
        />,
        <Resource name="smartsheet" />,
        <Resource name="reports/reports" />,
        <Resource name="reports/occupancy" />,
        <Resource name="reports/resident-occupancy" />,
        <Resource name="reports/renewals" />,
        <Resource name="reports/free-offices" />,
        <Resource name="reports/offices" />,
        <Resource name="documents"
          create={DocumentsCreate}
        />,
        <Resource name="notes"
          create={NotesCreate}
        />,

        <Resource name="contract_discounts"
          create={ContractDiscountsCreate}
          edit={ContractDiscountsEdit}
          list={ContractDiscountsList}
          icon={ContractDiscount}
          options={{
            label: 'Discounts'
          }}
        />,
        <Resource name="contract_charges"
          create={ContractChargeCreate}
          edit={ContractChargeEdit}
        />,
        <Resource name="payment_schedules"
          icon={dollarIcon}
          list={PaymentsScheduleList}

        />

      ]}
    </Admin>
  );
};

export default App;
