import React from 'react';
import {Create, SimpleForm, ReferenceInput, SelectInput, required } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

import moment from 'moment';
import { parse } from 'query-string';

const NotesCreate = props => {
  const {contractId} = parse(props.location.search);
  const redirect = contractId ? `/contracts/${contractId}/show/notes` : `/contracts`;
  return (
    <Create {...props}>
      <SimpleForm
        defaultValue={{ created_at: moment.utc(), contractId }}
        redirect={redirect}
      >
        <ReferenceInput label="Contract" source="contractId" reference="contracts" validate={[required()]}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <RichTextInput source="body"/>
      </SimpleForm>
    </Create>
  );
};

export default NotesCreate;