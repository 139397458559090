import React from 'react';
import { Create, email, regex, ReferenceInput, required, AutocompleteInput, SimpleForm, TextInput } from 'react-admin';

const AdminsCreate = props => (
  <Create {...props}>
    <SimpleForm redirect={basePath => basePath}>
      <TextInput source="email" validate={[required(), email()]} />
      <TextInput source="firstName" validate={[required(), regex(/^[a-zA-Z\s]+$/, 'Only A-Z a-z and whitespace allowed')]} />
      <TextInput source="lastName" validate={[required(), regex(/^[a-zA-Z\s]+$/, 'Only A-Z a-z and whitespace allowed')]} />
      <TextInput source="jobTitle" validate={[regex(/^[a-zA-Z\s]+$/, 'Only A-Z a-z and whitespace allowed')]} />
      <ReferenceInput label="Space" source="spaceId" reference="spaces" allowEmpty sort={{ field: 'name', order: 'ASC' }}>
        <AutocompleteInput optionText={choice => choice.name}/>
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default AdminsCreate;
