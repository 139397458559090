import moment from 'moment';
import numeral from 'numeral';
import React, { Fragment } from 'react';
import {
  Datagrid,
  Filter,
  List,
  DateField,
  ReferenceField,
  TextField,
  TextInput,
  BooleanField,
  BooleanInput,
  SearchInput,
  AutocompleteArrayInput,
  FunctionField
} from 'react-admin';

import DeleteButtonWithConfirmation from '../layout/DeleteButtonWithConfirmation';

const ContractsActionButtons = props => (
  <Fragment>
    <DeleteButtonWithConfirmation {...props} />
  </Fragment>
);

const plans = [
  { id: 'Local', name: 'Local' },
  { id: 'Resident', name: 'Resident' },
  { id: 'Dedicated Desk', name: 'Dedicated Desk'}
]

const ContractsFilters = props => (
  <Filter {...props}>
    <TextInput source="name" />
    <AutocompleteArrayInput source="plan" choices={plans} translateChoice={false} />
    <BooleanInput source="expired"/>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

const rowStyle = (record, index) => ({
  backgroundColor: `${!record.rolling ? (moment().isAfter(moment(record.leavingDate || record.endDate)) ? 'rgba(255, 0, 0, 0.2)' : 'rgba(255, 255, 0, 0.2)') : 'transparent'}`
})

const ContractsArchiveList = props => (
  <List
    {...props}
    perPage={25}
    filters={<ContractsFilters />}
    bulkActionButtons={<ContractsActionButtons />}
  >
    <Datagrid rowClick={row => `/contracts/${row}/show`} rowStyle={rowStyle}>
      <TextField source="name" />
      <TextField source="company" />
      <ReferenceField allowEmpty label="Space" source="spaceId" reference="spaces">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="plan" />
      <FunctionField label="Monthly fee" render={record => `£${numeral(record.income).format('0,0.00')}`} />
      <TextField source="source" />
      <DateField source="startDate" />
      <DateField source="endDate" />
      <BooleanField source="depositPaid" />
      <TextField source="noticePeriod" />
      <BooleanField source="rolling" />
      <DateField source="leavingDate" />
    </Datagrid>
  </List>
);
export default ContractsArchiveList;
