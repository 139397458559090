import React from 'react';
import {
  NumberInput,
  required,
  minValue,
  SimpleForm,
  SaveButton,
  Toolbar,
  SelectInput,
} from 'react-admin';
import { DateTimeInput } from 'react-admin-date-inputs';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';


const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const DiscountEditToolbar = withStyles(styles)(({ classes, ...props }) => (
  <Toolbar className={classes.toolbar} {...props}>
    <SaveButton {...props} />
  </Toolbar>
));

const DisconutsForm = ({ formValues, dispatch, ...props }) => (
  <SimpleForm {...props} redirect={basePath => basePath} toolbar={<DiscountEditToolbar />}>
    <NumberInput source="codesNumber" label="Number of codes to generate" validate={[required(), minValue(1)]} inputProps={{ min: 1 }} />
    <NumberInput source="discount" label="Discount value" validate={[required(), minValue(1)]} inputProps={{ min: 1 }} />
    <SelectInput source="discountUnit" validate={[required()]} choices={[{ id: '£', name: '£' }, { id: '%', name: '%' }]} />
    <DateTimeInput source="expirationDate" label="Expiration date" options={{ clearable: true, ampm: false }} validate={[required()]} />
  </SimpleForm>
);

const mapStateToProps = state => ({
  formValues: getFormValues('record-form')(state) || {},
});

const enhance = connect(mapStateToProps);

export default enhance(DisconutsForm);
