import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE_MANY,
  DELETE,
  GET_MANY,
  GET_MANY_REFERENCE,
  HttpError,
} from 'react-admin';
import API from '../_app/api';

const { REACT_APP_RAICHU_URL: apiUrl } = process.env;

export default async (type, resource, params) => {
  let url = '';
  let query = {};
  let method = 'get';

  // console.log('TYPE: ', type);
  // console.log('RES: ', resource);
  // console.log('PARS: ', params);

  switch (type) {
    case GET_LIST: {
      const {page, perPage} = params.pagination;
      const {q: phrase, ...filters} = (params.filter || {});
      query = omitBy(
        {
          limit: perPage,
          page,
          sort: params.sort.field,
          order: params.sort.order,
          filters: phrase || filters ? {phrase, ...filters} : undefined,
        },
        isUndefined
      );
      url = `${apiUrl}/${resource}`;
      break;
    }
    case GET_ONE:
      url = `${apiUrl}/${resource}/${params.id}`;
      break;
    case CREATE:
      url = `${apiUrl}/${resource}`;
      method = 'post';
      break;
    case UPDATE:
      url = `${apiUrl}/${resource}/${params.id}`;
      method = 'put';
      break;
    case UPDATE_MANY:
      query = {ids: params.ids};
      url = `${apiUrl}/${resource}`;
      method = 'put';
      break;
    case DELETE:
      url = `${apiUrl}/${resource}/${params.id}`;
      method = 'delete';
      break;
    case DELETE_MANY:
      query = {ids: params.ids};
      url = `${apiUrl}/${resource}`;
      method = 'delete';
      break;
    case GET_MANY:
      query = {ids: params.ids};
      url = `${apiUrl}/${resource}`;
      break;
    case GET_MANY_REFERENCE:
      query = {[params.target]: params.id, ...params.filter};
      url = `${apiUrl}/${resource}`
      break;
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }

  try {
    const {data} = await API({
      url,
      method,
      params: {
        ...query,
      },
      data: {
        ...get(params, 'data', {}),
      },
    });
    return convertHTTPResponse({id: params.id, ...data}, type);
  } catch (error) {
    throw new HttpError(error.response.data.message, error.response.status, error.response.data.errors);
  }
}
function convertHTTPResponse(response, type) {
  switch (type) {
    case GET_LIST:
      return {
        data: response.data.map(item => ({
          ...item,
          id: item._id || item.ID,
        })),
        total: response.total,
      };
    case GET_MANY:
      return {
        data: response.data.map(item => ({
          ...item,
          id: item._id || item.ID,
        })),
      };
    case GET_ONE:
      return {
        data: {
          ...response.data,
          id: response.data._id,
        },
      };
    case GET_MANY_REFERENCE:
        return {
          data: response.data.map(item => ({
            ...item,
            id: item._id || item.ID,
          })),
          total: response.total,
        };
    case CREATE:
      return {data: {...response.data, id: get(response, 'data._id')}};
    case UPDATE:
      return {data: {...response.data, id: response.data._id}};
    case DELETE:
      return {data: {...response}};
    default:
      return {data: response.data};
  }
}
