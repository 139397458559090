// in src/comment/commentActions.js
export const SET_SPACE = 'SET_SPACE';
export const SPACE_SET = 'SPACE_SET';
export const setSpace = (space) => ({
    type: SET_SPACE,
    payload: {space},
    meta: { refresh: true },
});
export const spaceSet = (set) => ({
    type: SPACE_SET,
    payload: true
})