export const ROLES_ADMINISTRATIVE = [
  { id: 'ADMIN', name: 'Administrator' },
  { id: 'USER', name: 'User' },
];

export const ROLES = [
  ROLES_ADMINISTRATIVE,
];

export const TZ = 'Europe/London';

export const LOCAL_STORAGE_KEYS = {
  token: 'office-manager-token',
  role: 'office-manager-role',
  space: 'office-manager-space',
};

export const DISCOUNT_TYPE = {
  BROKER: 'broker',
  REFERRAL: 'referral',
};

export const RESOURCE_TYPE = {
  PRIVATE_OFFICE: 'private_office',
  MEETING_ROOM: 'meeting_room',
};

export const PLANS = [
  { id: 'Resident', name: 'Resident' },
  { id: 'Local', name: 'Local' },
  { id: 'Dedicated Desk', name: 'Dedicated Desk'}
];

export const BUSINESS_TYPES = [
  { id: 'Business', name: 'Business' },
  { id: 'Sole-trader', name: 'SoleTrader' },
]

export const SOURCES = [
  { id: 'Direct', name: 'Direct' },
  { id: 'Broker', name: 'Broker' },
];

export const NOTICE_PERIODS = [
  { id: 1, name: '1 month' },
  { id: 2, name: '2 months' },
  { id: 3, name: '3 months' },
]

export const INDUSTRIES = [
  { id: 'Accounting', name: 'Accounting' },
  { id: 'Architecture & Interior Design', name: 'Architecture & Interior Design' },
  { id: 'Business Services', name: 'Business Services' },
  { id: 'Charities & NGOS', name: 'Charities & NGOS' },
  { id: 'Copywriting, PR & Communications', name: 'Copywriting, PR & Communications' },
  { id: 'CRM & Sales', name: 'CRM & Sales' },
  { id: 'Design & Media', name: 'Design & Media' },
  { id: 'E-commerce', name: 'E-commerce' },
  { id: 'Education', name: 'Education' },
  { id: 'Engineering', name: 'Engineering' },
  { id: 'Energy', name: 'Energy' },
  { id: 'Entertainment', name: 'Entertainment' },
  { id: 'Events Services', name: 'Events Services' },
  { id: 'Farming', name: 'Farming' },
  { id: 'Fashion & Apparel', name: 'Fashion & Apparel' },
  { id: 'Film, Photography, Music & Sound', name: 'Film, Photography, Music & Sound' },
  { id: 'Financial Services', name: 'Financial Services' },
  { id: 'Food & Beverages', name: 'Food & Beverages' },
  { id: 'Graphic Design', name: 'Graphic Design' },
  { id: 'Healthcare', name: 'Healthcare' },
  { id: 'Hospitality', name: 'Hospitality' },
  { id: 'HR & Recruiting', name: 'HR & Recruiting' },
  { id: 'Insurance', name: 'Insurance' },
  { id: 'Legal Services', name: 'Legal Services' },
  { id: 'Marketing & Advertising', name: 'Marketing & Advertising' },
  { id: 'Museums & Institutions', name: 'Museums & Institutions' },
  { id: 'Online Media', name: 'Online Media' },
  { id: 'Philanthropy & Development', name: 'Philanthropy & Development' },
  { id: 'Printing Services', name: 'Printing Services' },
  { id: 'Real Estate', name: 'Real Estate' },
  { id: 'Retail', name: 'Retail' },
  { id: 'Research', name: 'Research' },
  { id: 'Security', name: 'Security' },
  { id: 'Social Enterprise', name: 'Social Enterprise' },
  { id: 'Travel', name: 'Travel' },
  { id: 'Venture Capital & Private Equity', name: 'Venture Capital & Private Equity' },
  { id: 'Web, IT & Software', name: 'Web, IT & Software' },
  { id: 'Wellness & Fitness', name: 'Wellness & Fitness' },
  { id: 'Other', name: 'Other' },
];
