
import { ReferenceField, Datagrid, TextField, ArrayField, FunctionField, DateField, NumberField, BooleanField } from 'react-admin';
import React from 'react';
import compose from 'recompose/compose';
import numeral from 'numeral';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { pick } from 'lodash';
import DashList from './DashList';

const styles = {
  title: {
    marginTop: 35,
    marginBottom: 15,
  }
}
const EndingSoon = ({ classes, ...props }) => {

      return (
        <>
          <Typography component="h2" variant="title" gutterBottom className={classes.title}>
          Confirmed Contracts Ending
          </Typography>
          <DashList
            {...pick(props, ['location'])}
            title=" "
            resource='reports/offices'
            basePath='/reports/offices'
            filters={null}
            actions={null}
            bulkActionButtons={false}
            sort={{field: 'leavingDate', order: 'ASC'}}
          >
            <Datagrid rowClick={row => `/contracts/${row}/show`}>
              <ReferenceField  reference="spaces" source="spaceId" allowEmpty linkType={false}>
                <TextField source="name"/>
              </ReferenceField>
              <FunctionField label="Name" render={record => record.company || record.name} />
              <ArrayField source="offices">
                <Datagrid>
                  <TextField source="number" label="Name" />
                  <NumberField label="Number of Desks" source="numberOfDesks"/>
                  <FunctionField label="Price per desk" render={record => `£${numeral(record.price).format('0,0')}`} />
                  <BooleanField label="Internal" source="internal"/>
                </Datagrid>
              </ArrayField>
              <FunctionField label="Current total revenue" render={record => `£${numeral(record.actualIncome).format('0,0')}`} />
              <DateField source="leavingDate" label="Contract end date" />
              <FunctionField label="Age" sortBy="leavingDate" render={r => <div style={{color: r.age <= 0 ? 'green' : 'red'}}>{numeral(r.age).format('0')}</div>}/>}/>

            </Datagrid>
          </DashList>
        </>
      );
};

const enhance = compose(
  withStyles(styles)
);

export default enhance(EndingSoon
  );
