import React, { Component } from 'react';
import { ListController } from 'ra-core';
import { ListView } from 'react-admin'
import queryReducer from './queryReducer';
import { changeListParams, crudGetList } from 'ra-core';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import {connect} from 'react-redux'


class DashList extends Component {

  componentWillReceiveProps(newProps) {
    const {params: oldParams} = this.props;
    const {params: newParams } = newProps;

    // console.log('OLD: ', oldParams);
    // console.log('NEW: ', newParams);

    if(
      !isEqual(oldParams.page, newParams.page) ||
      !isEqual(oldParams.perPage, newParams.perPage) ||
      !isEqual(oldParams.sort, newParams.sort) ||
      !isEqual(oldParams.order, newParams.order) ||
      !isEqual(this.props.filter, newProps.filter)
    ) {
      const {page, perPage, sort, order} = newParams;
      this.props.crudGetList(
        this.props.resource,
        {page, perPage},
        {field: sort, order},
        {...newProps.filter}
      )
    }
  }

  changeParams(action) {
    // console.log('ACTI?on: ', action)
    const newParams = queryReducer(this.props.params, action);
    // console.log('new pager: ', newParams);

    // console.log('new props', this.props);

    this.props.changeListParams(this.props.resource, newParams);
  }

  render() {

    const setSort = sort => this.changeParams({ type: 'SET_SORT', payload: { sort } });

    const setPage = page => this.changeParams({ type: 'SET_PAGE', payload: page });

    const setPerPage = perPage => this.changeParams({ type: 'SET_PER_PAGE', payload: perPage });

    const setFilters = filter => this.changeParams({ type: 'SET_FILTER', payload: filter });

    return  (
      <ListController {...this.props}>
        {controllerProps => <ListView
          {...this.props}
          {...controllerProps}
          setSort={setSort}
          setPage={setPage}
          setPerPage={setPerPage}
          setFilters={setFilters}
          filterValues={this.props.filter}
          page={this.props.params.page || 1}
          perPage={this.props.params.perPage || 10}
        />}
      </ListController>
    );

  }
}
const mapStateToProps = (state, props) => {
  // console.log('STATE: ', state)
  return {
    ...props,
    params: get(state, `admin.resources[${props.resource}].list.params`)
  };
}

const mapDispatchToProps = {
  changeListParams,
  crudGetList,
}

export default connect(mapStateToProps, mapDispatchToProps)(DashList);