import React from 'react';
import { Create } from 'react-admin';
import DiscountsForm from './DiscountsForm';

const DiscountCreate = props => (
  <Create {...props}>
    <DiscountsForm />
  </Create>
);

export default DiscountCreate;
