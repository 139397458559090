import React from 'react';
import { getFormValues } from 'redux-form';
import {Query, Create, SimpleForm, DateInput, NumberInput, ReferenceInput, SelectInput, required, minValue } from 'react-admin';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { get, pick } from 'lodash';
import { parse } from 'query-string';
import classnames from 'classnames';
import PaymentsSchedule from './PaymentsSchedule';
import Typography from '@material-ui/core/Typography';

const styles = {
  duration: {
    display: 'inline-block',
  },
  dateEnd: {
    display: 'inline-block',
  },
  title: {
    marginTop: 20,
  }
}

const Duration = ({ contractId, classes }) => {
  return (
    <Query
      type="GET_ONE"
      resource="contracts"
      payload={{
        id: contractId
      }}
    >
      {({data, loading}) => {
        if (loading) {
          return null;
        }
        return (
          <SelectInput formClassName={classnames(classes.duration)} source="duration" validate={[required()]}
            choices={[
              { id: 1, name: '1 month' },
              { id: 2, name: '2 months' },
              { id: 3, name: '3 months' },
              { id: -1, name: 'Whole Contract' },
              { id: 0, name: 'Custom' },
              { id: 99, name: 'Forever', disabled: !get(data, 'rolling') }
            ]}
          />
          )
        }
      }
    </Query>
  );
};

const ContractsDiscountCreate = ({formValues, classes, ...props}) => {
  const {contractId, date, end} = parse(props.location.search);
  const redirect = contractId ? `/contracts/${contractId}/show/discounts` : `/contracts`;
  const dateEnd = end ? moment(end).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
  return (
    <>
    <Create {...props}>
      <SimpleForm
        {...props}
        defaultValue={{ contractId, dateStart: date ? moment(date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'), dateEnd }}
        redirect={redirect}
      >
        <ReferenceInput label="Contract" source="contractId" reference="contracts" validate={[required()]}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <SelectInput source="discountUnit" validate={[required()]} choices={[{ id: '£', name: '£' }, { id: '%', name: '%' }]} />
        <NumberInput source="discount" label="Discount value" validate={[required(), minValue(1)]} inputProps={{ min: 1 }} />
        <DateInput source="dateStart" label="Start date" inputProps={{min: moment(date).format('YYYY-MM-DD'), max: '2999-12-31'}} />

        <Duration classes={classes} contractId={contractId} />
        {get(formValues, 'duration')  ===  0 && [
          <DateInput formClassName={classnames(classes.dateEnd)} source="dateEnd" label="End date" inputProps={{ min: get(formValues, 'dateStart'), max: dateEnd}}/>
        ]}
      </SimpleForm>
    </Create>
    <Typography component="h2" variant="title" gutterBottom gutterTop className={classes.title}>
      Payments Schedule
    </Typography>
    <PaymentsSchedule id={contractId} basePath='/contracts' record={{id: contractId}} filter={{...pick(formValues, 'discountUnit', 'discount', 'dateStart', 'duration', 'dateEnd'), type: 'discount'}}/>

    </>
  );
};

const mapStateToProps = state => ({
  formValues: getFormValues('record-form')(state) || {},
});

const enhance = connect(mapStateToProps);

export default compose(withStyles(styles), enhance)(ContractsDiscountCreate);
// export default NotesCreate;
