const convertFileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.readAsDataURL(file.rawFile);

  reader.onload = () => {
    resolve(reader.result);
  };

  reader.onerror = reject;
});

const addUploadFeature = requestHandler => async (type, resource, params) => {

  if ((type === 'CREATE' || type === 'UPDATE') && (resource === 'contracts' || resource === 'documents')) {
    // if (params.data.pdf && params.data.pdf.rawFile && params.data.src && params.data.src.startsWith('blob:')) {
    if (params.data.pdf) {
      // console.log('PDF: ' + JSON.stringify(params.data.pdf))
      let pdfData = undefined;
      try {
        pdfData = await convertFileToBase64(params.data.pdf);
      } catch (e) {
        // nop
      }
      return requestHandler(type, resource, {
        ...params,
        data: {
          ...params.data,
          pdfData,
        }
      });
    }
  }

  return requestHandler(type, resource, params);
};

export default addUploadFeature;
