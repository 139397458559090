import React from 'react';
import { Button, Link } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { ContractDiscount } from '../_app/icons';

const styles = {
  button: {
    color: '#fff !important',
  },
};

const AddContractDiscountButton = withStyles(styles)(({classes, record}) => (
  <Button
    className={classes.button}
    style={{paddinTop: 8, paddingBottom: 8, marginLeft: 10}}
    variant="raised"
    component={Link}
    to={{
      pathname: "/contract_discounts/create",
      search: `?contractId=${record.id}&date=${record.startDate}&end=${record.endDate}`
    }}
    label="Add a discount"
  >
    <ContractDiscount/>
  </Button>
));

export default AddContractDiscountButton;