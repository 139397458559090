import moment from 'moment-timezone';
import numeral from 'numeral';
import React from 'react';
import {
  Datagrid,
  Filter,
  List,
  ReferenceField,
  TextField,
  SearchInput,
  ReferenceArrayField,
  ArrayField,
  SingleFieldList,
  ChipField,
  FunctionField,
  downloadCSV,
} from 'react-admin';
import Tooltip from '@material-ui/core/Tooltip';
import { DateInput } from 'react-admin-date-inputs';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import PaymentsScheduleListActions from './PaymentsScheduleListActions';

const TZ = 'Europe/London';

const Filters = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    {/* <AutocompleteArrayInput source="plan" choices={plans} translateChoice={false} alwaysOn /> */}
    <DateInput
      source="paymentDate"
      alwaysOn
      options={{ format: 'MMM/YYYY', openTo: 'year' }}
    />
  </Filter>
);

const rowStyle = (record, index) => ({
  backgroundColor: `${
    !record.rolling
      ? moment().isAfter(moment(record.leavingDate || record.endDate))
        ? 'rgba(255, 0, 0, 0.2)'
        : 'rgba(255, 255, 0, 0.2)'
      : 'transparent'
  }`,
});

const exporter = async (records, fetchRelatedRecords) => {
  const spaces = await fetchRelatedRecords(records, 'spaceId', 'spaces');
  const offices = await fetchRelatedRecords(
    records,
    'officeIds',
    'private_offices'
  );
  const data = records.map(record => ({
    Name: record.name,
    Company: record.company,
    Space: spaces[record.spaceId].name,
    Offices: (record.officeIds || [])
      .map(officeId => offices[officeId].name)
      .join(', '),
    Plan: record.plan,
    'Gross Income': `${numeral(record.grossIncome).format('000.00')}`,
    Discount: `${numeral(record.discount).format('000.00')}`,
    Charges: (record.charges || [])
      .map(charge => `${numeral(charge).format('000.00')}`)
      .join(', '),
    'Net Price': `${numeral(record.netPrice).format('000.00')}`,
    'Payment Date': moment
      .tz(record.paymentDate || record.leavingDate, TZ)
      .format('MMM/YYYY'),
  }));
  const csv = convertToCSV({ data });
  downloadCSV(csv, `payment_schedules_${moment().toISOString()}`);
};

export const PriceValueWithTooltip = ({ value, tooltipTitle }) => (
  <Tooltip title={tooltipTitle} placement="right">
    <span>{`£${numeral(value).format('0,00.00')}`}</span>
  </Tooltip>
);

const PaymentsScheduleList = props => (
  <List
    {...props}
    filters={<Filters />}
    bulkActionButtons={false}
    actions={<PaymentsScheduleListActions />}
    filterDefaultValues={{ paymentDate: moment.tz(TZ).format('MMM/YYYY') }}
    exporter={exporter}
  >
    <Datagrid rowClick={row => `/contracts/${row}/show`} rowStyle={rowStyle}>
      <TextField source="name" sortBy="firstName" />
      <TextField source="company" />
      <ReferenceField
        allowEmpty
        label="Space"
        source="spaceId"
        reference="spaces"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceArrayField
        label="Offices"
        source="officeIds"
        reference="private_offices"
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField source="plan" />
      <FunctionField
        label="Gross Income"
        render={({ grossIncome }) => (
          <PriceValueWithTooltip
            value={grossIncome}
            tooltipTitle="list price, pro rata if start/end mid month, + charges"
          />
        )}
      />
      <FunctionField
        label="Discount"
        render={({ discount }) => (
          <PriceValueWithTooltip
            value={discount}
            tooltipTitle="pro rata if start/end mid month, doesn't include charges"
          />
        )}
      />
      <ArrayField source="charges">
        <SingleFieldList>
          <FunctionField
            label="Charges"
            render={discount => (
              <ChipField source="discount" record={{ discount }} />
            )}
          />
        </SingleFieldList>
      </ArrayField>
      <FunctionField
        label="Net Price"
        render={({ netPrice }) => (
          <PriceValueWithTooltip
            value={netPrice}
            tooltipTitle="list price, pro rata if start/end mid month, + charges - discount"
          />
        )}
      />
      <FunctionField
        label="Payment Date"
        render={r =>
          moment.tz(r.paymentDate || r.leavingDate, TZ).format('MMM/YYYY')
        }
      />
    </Datagrid>
  </List>
);

export default PaymentsScheduleList;
